<template>
  <div>
    <Menu />

    <section>
      <div class="container-fluid pt-5 mt-5 g-0">
        <div class="row g-0 text-center">
          <img src="/images/team-banner.jpg" class="img-fluid">
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center pt-5 pb-5">
            <h2>Our Agents</h2>
            <p>We're excited to help you achieve your property dreams and discover your perfect home and idyllic lifestyle.</p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="container-fluid bg-light">
            <div class="row">
              <div class="col-md-3 p-3">
                <img src="/images/team/amanda.jpg" class="img img-fluid" alt="">
              </div>
              <div class="col-md-9 p-3">
                <div class="card-body text-start">
                  <p><a href="https://www.chaseveritt.co.za/agents/amanda-colombo/847723/"><strong>Amanda Colombo - Principle and partner</strong></a></p>
                  <p><a href="https://wa.me/27832581212" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                  <p><i class="bi bi-telephone-fill"></i> +27 83 258 1212</p>
                  <p><i class="bi bi-envelope-fill"></i> amanda.c@everitt.co.za</p>
                  <p>Amanda is renowned for her persistence, perseverance and integrity in sales excellence; however, it is her singular focus on client experience and after-sales service that defines her success. She boasts a very strong network of clients, with 80% of sales coming from client referrals.</p>
                  <p>She has consistently shown a high level of dedication and expertise in the real estate industry, resulting in multiple awards and accolades over the years. From prestigious industry awards to internal recognition for outstanding performance, Amanda has rightfully earned a reputation for excellence. Together with Anneke Buys, co-owner of the Chas Everitt Luxury Winelands Estates and Franschhoek franchise, this award-winning team achieved record sales in 2021 and 2022 and is a leading example of the Chas Everitt purpose to make a positive, lasting impact on customers. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid bg-light">
            <div class="row">
              <div class="col-md-3 p-3">
                <img src="/images/team/anneke.jpg" class="img img-fluid" alt="">
              </div>
              <div class="col-md-9 p-3">
                <div class="card-body text-start">
                  <p><a href="https://www.chaseveritt.co.za/agents/anneke-buys/847722/"><strong>Anneke Buys - Sales Associate and partner</strong></a></p>
                  <p><a href="https://wa.me/27833570082" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                  <p><i class="bi bi-telephone-fill"></i> +27 83 357 0082</p>
                  <p><i class="bi bi-envelope-fill"></i> anneke.b@everitt.co.za</p>
                  <p>Anneke has obtained a wealth of property knowledge after 20 years of experience. She initially worked as a transfer attorney at Val de Vie after studying law, but decided her passion for real estate, sales and people was difficult to ignore. 
                  </p><p>With a special ability to truly understand her clients’ needs, she provides a customised service that upholds the highest quality standards. Clients testify that she always goes the extra mile, always has the biggest smile on her face and always exudes positivity. Having achieved record sales in 2021 and 2022, Anneke is part of an award-winning team with Amanda Colombo, co-owner of the Chas Everitt Luxury Winelands Estates and Franschhoek franchise.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section>
      <div class="container pb-5">
        <div class="row justify-content-center">

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/renata.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/renata-gomes/863053/"><strong>Renata Gomes - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27827791291" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 82 779 1291</p>
                <p><i class="bi bi-envelope-fill"></i> renatag@everitt.co.za</p>
                <p>Renata joined Chas Everitt in 2019 and has been with Luxury Winelands Estates and in Paarl for six years. This gives her unique knowledge of the beautiful town and an intimate understanding of what makes each distinct residential niche so special. She is known for integrity, diplomacy and sincerity in all her dealings, striving to be someone in whom her clientele and colleagues can put their trust and faith. While assertive and effective without being too aggressive, Renata provides the same level of unmatched service for all her buyer and sellers. Above all, she values relationships over transactions and much of her business comes from repeat clients or referrals. </p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/renee.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/renee-lister-james/871064/"><strong>Renee Lister-James - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27825705542" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 82 570 5542</p>
                <p><i class="bi bi-envelope-fill"></i> renee@everitt.co.za</p>
                <p>Renée is passionate about selling property in the beautiful Franschhoek Valley. She adores her team and the incredible fun they have together, believing that it makes a difference when you love what you do! As an intuitive and committed agent, she looks forward to introducing her clients to the unique, magical Franschhoek Valley and helping families find their perfect home.  When her shoes are off for the day, Renée loves being a wife to her husband, Wayne, and mom to her two amazing sons, Steven and Justin. Although her children are grown up, she loves the weekends that they all get together and spend time in their home. She also thoroughly enjoys exploring new restaurants and entertaining friends. </p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/leigh.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/leigh-robertson/862117/"><strong>Leigh Robertson - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27828828243" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 82 882 8243</p>
                <p><i class="bi bi-envelope-fill"></i> leighr@everitt.co.za</p>
                <p>Leigh worked in the financial services industry for 24 years before moving to the beautiful Cape Winelands and becoming involved in real estate. She has spent the past 19 years working in this industry, with a focus on exclusive security estate properties. She started working for Pearl Valley Golf Estate in 2005, managing their property division including the sales and rentals of property. In 2020, she joined Chas Everitt Luxury Winelands Estates and looks forward to many years of success working within this dynamic team of property professionals.  In recent years, Leigh and her partner have won numerous provincial and national property awards. This is testament to the relationships she has developed with clients, the trust earned, the service provided and the passion she has for what she does. </p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/andrea.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/aidan-litchfield/871093/"><strong>Andrea Vrana - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27829556564" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 82 955 6564</p>
                <p><i class="bi bi-envelope-fill"></i> andrea.v@everitt.co.za</p>
                <p>Andrea has over 15 years of experience in the pharmaceutical industry, where she has proven herself as a dedicated professional, garnering accolades and recognitions that underscore her commitment to excellence. Andrea’s experience has allowed her to navigate complex challenges with finesse. Her ability to communicate effectively and empathetically has enabled her to build rapport with clients, colleagues, experts and leaders. Her extensive network serves as a testament to her influence and credibility, while providing insights and opportunities that enrich her professional endeavours. Andrea has now channelled her passion and expertise into real estate. Leveraging her strategic mindset, interactive skills and extensive network, she is committed to making a meaningful impact. Driven by a relentless pursuit of excellence and fuelled by a genuine desire to create value, Andrea is poised to thrive, bringing a fresh perspective and a wealth of experience to the world of real estate.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/annelize.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/annelize-reinmuller/862116/"><strong>Annelize Reinmuller - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27767889918" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 76 788 9918</p>
                <p><i class="bi bi-envelope-fill"></i> annelizer@everitt.co.za</p>
                <p>Annelize is a Stellenbosch native with a passion for travel and property dreams. Drawn to adventure, she pursued a degree in tourism and a job aboard five-star luxury small cruise lines. In 2012, she landed at Pearl Valley Properties, starting as a rental agent and transitioning to the sales team, honing her skills and knowledge. In 2020, she joined Chas Everitt Luxury Winelands Estates, driven by a desire to provide exceptional service and guide clients through their property journeys. Annelize and her partner have been bestowed with various accolades and awards over the years. With a deep understanding of Pearl Valley and the area property market, a genuine passion for people, and a dedication to personalised service, Annelize is committed to turning her client’s property dreams into a reality.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team-sydne.png" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/sydne-louw/870107/"><strong>Sydne Louw - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27724379508" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 72 437 9508</p>
                <p><i class="bi bi-envelope-fill"></i> sydne.louw@everitt.co.za</p>
                <p>Sydne has a strong work ethic, is dedicated and passionate about real estate and will guide clients through their real estate experience as buyer or seller, building a relationship of trust and reliability.  Chas Everitt has a strong focus on training and mentoring programmes and this knowledge, coupled with the use of innovative tools and technology, ensures that Sydne is equipped with the valuable skills essential for success in real estate.  Whether it be in negotiations, educating clients or expertise in marketing properties, Sydne always adds value and delivers a memorable experience. She recognises and values the trust her clients place in her, and she is committed to ensuring that her service exceeds their expectations.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/wanneke.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/wanneke-botha/877958/"><strong>Wanneke Botha - Sales and Rental Agent</strong></a></p>
                <p><a href="https://wa.me/27715239602" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 71 523 9602</p>
                <p><i class="bi bi-envelope-fill"></i> wanneke.botha@everitt.co.za</p>
                <p>Wanneke is a vibrant estate agent in the heart of Franschhoek. Having called this picturesque village home for over a decade, she is here to help clients turn the dream of a lifetime into reality. With a warm, welcoming spirit, an innate ability to understand the unique desires of every client, and a deep-seated passion for people, Wanneke is poised to be a transformative force in the Franschhoek real estate landscape.  When she isn’t diligently assisting clients, she can be found embracing the thrill of mountain biking in the majestic Franschhoek mountains. At the heart of her life are two cherished fur babies, Zula and Indi who bring boundless joy and love. Come Saturdays, you’ll find Wanneke immersing herself in the vibrant tapestry of the Franschhoek village market. And throughout the week, she can be spotted at one of the town's beloved coffee shops, revelling in the art of conversation and connection.</p>
              </div>
            </div>
          </div>

          <!-- -->
          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/marli.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/marli-scheppel/879418/"><strong>Marli Scheppel - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27839885691" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 83 988 5691</p>
                <p><i class="bi bi-envelope-fill"></i> marli.scheppel@everitt.co.za</p>
                <p>Marli has over 17 years of real estate experience, equipping her with an invaluable wealth of knowledge in this competitive industry. With her diverse expertise, she has proven herself as a dedicated, accomplished and committed professional. She has strong communication, interpersonal and negotiation skills, and combined with her unique insights, these have been instrumental in extending her network, cultivating dedicated engagements and building long-standing relationships. Marli strives for excellence and innovation, always with a client-focused approach. Real estate is her passion and she is committed to creating a meaningful impact on the industry. </p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/nathalie.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="#"><strong>Nathalie Hecart - Rental Agent</strong></a></p>
                <p><a href="https://wa.me/27604118309" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 60 411 8309</p>
                <p><i class="bi bi-envelope-fill"></i> nathalie.hecart@everitt.co.za</p>
                <p>With 17 years of marketing experience in the pharmaceutical industry in France, Nathalie Hecart excelled in establishing commercial strategies. Her career demanded constant self-reflection, active listening, anticipation, and evolution, skills honed through numerous market studies and collaborative teamwork, which also fostered her empathy. Fifteen years ago, Nathalie moved to South Africa and embraced a new challenge by purchasing a lodge, creating a luxury safari business. This venture demonstrated that there are no age limits to reinventing oneself and pursuing new goals. Recently, Nathalie transitioned into the property market, joining Chas Everitt as an agent. She is thrilled to work with an extraordinary and highly professional team, eager to leverage her skills, passion, adaptability, and extensive life experience to serve customers every day.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/betro.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/betro-groenewald/862207/"><strong>Betro van Zyl - Rental Agent</strong></a></p>
                <p><a href="https://wa.me/27726011344" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 72 601 1344</p>
                <p><i class="bi bi-envelope-fill"></i> luxuryrentals@everitt.co.za</p>
                <p>Born and raised in Riviersonderend, Betro enjoys and understands the unique charm and appeal of the Winelands region. She is a passionate and dedicated rental agent with Chas Everitt Winelands Estates, who leverages her in-depth market knowledge, effective negotiation skills and personalised approach to ensure a smooth and successful experience for every owner and tenant.  With a contagious enthusiasm and a genuine love for connecting with individuals, she is driven by the desire to empower others on their property journeys. When not helping tenants find their dream homes, she enjoys a good book, cycling or hiking in the mountains.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/stephanie.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="#"><strong>Stephanie Joubert - Rental Agent</strong></a></p>
                <p><a href="https://wa.me/27824824199" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 82 482 4199</p>
                <p><i class="bi bi-envelope-fill"></i> stephanie.joubert@everitt.co.za</p>
                <p>With 20 years of experience as an Interior Designer, Stephanie has mastered the art of transforming spaces to reflect her clients unique tastes and requirements. Her deep understanding of spaces and project management has consistently brought visions to life, balancing creativity with practicality. Now, transitioning into real estate, she leverages her design expertise to identify property potentials and optimize spaces for buyers, tenants and investors. Her goal is to understand and fulfil clients needs, guiding them to find inspiring and functional properties. Combining design insights with real estate, she is committed to delivering exceptional service and results.</p>
              </div>
            </div>
          </div>

          <div class="col-md-3 p-3">
            <div class="card">
              <img src="/images/team/aidan.jpg" class="card-img-top" alt="">
              <div class="card-body text-start">
                <p><a href="https://www.chaseveritt.co.za/agents/aidan-litchfield/871093/"><strong>Aidan Litchfield - Sales Agent</strong></a></p>
                <p><a href="https://wa.me/27748906249" target="_blank"><i class="bi bi-whatsapp"></i> WhatsApp</a></p>
                <p><i class="bi bi-telephone-fill"></i> +27 74 890 6249</p>
                <p>Aidan is more than just your average real estate agent, he’s your enthusiastic advocate and problem-solving partner in the exciting world of property. Driven by optimism and a love for connecting people, he thrives on helping clients find their perfect fit, whether it’s a dream home, a lucrative investment or a quick, smooth sale. His passion for life and all things translates into a dynamic, results-oriented approach, while he also brings infectious energy to every interaction, ensuring the process is as enjoyable as it is successful.</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>
<style scoped>

.card {
  border: none;
  border-radius: 0px;
}

.card-body a {
  text-decoration: none;
  color: #124e83;
}
.card-body p {
  color: #124e83;
}

.card-body strong {
  color: #124e83;
}
</style>